import { AutoCenter, Button, Grid, Image, List, Space, Toast } from "antd-mobile";
import "./PersonalCenter.css"
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser, logout } from "../../axios";
import { clear, set } from "../../store/userSlice";
import Footer from "../../components/footer";
import AllInPNG from "../../assets/all-in-history.png";
import TrackHistoryPNG from "../../assets/track-history.png";
import SaveHistoryPNG from "../../assets/save-history.png";
import StoreInfo from "../../assets/store-info.png";
import UserManagementPNG from "../../assets/user-management.png";
import SharePNG from "../../assets/share.png";
import FeedbackPNG from "../../assets/feedback.png";
import AboutPNG from "../../assets/about.png";
import DefaultUserPNG from "../../assets/store-info.png"

import { BellOutline, PayCircleOutline, RightOutline, SetOutline, StopOutline } from "antd-mobile-icons";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default function PersonalCenter() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user?.user || {});
    const [noTicketNum, setNoTicketNum] = useState(0);
    const [waitForLotteryNum, setWaitForLotteryNum] = useState(0);
    const [wonNum, setWonNum] = useState(0);
    const [todayWon, setTodayWon] = useState(0);
    const version = useMemo(() => localStorage.getItem('_v'), []);

    const handleLogout = async() => {
        localStorage.setItem('token', '');
        dispatch(clear());
        navigate("/login");
    }

    useEffect(() => {
        getUser({}).then(({data}) => {
            if(data.status === "error") {
                Toast.show(data.errorMessage);
            } else {
                dispatch(set(data.user));
                setNoTicketNum(data.noTicketNum);
                setWaitForLotteryNum(data.waitForLotteryNum);
                setWonNum(data.wonNum);
                setTodayWon(data.todayWon);
            }
        });
        const timmer = setInterval(() => {
            getUser({}).then(({data}) => {
              if(data.status === "error") {
              } else {
                  dispatch(set(data.user));
                  setNoTicketNum(data.noTicketNum);
                  setTodayWon(data.todayWon);
                  setWaitForLotteryNum(data.waitForLotteryNum);
                  setWonNum(data.wonNum);
              }
          });
          }, 10 * 1000);
          return () => clearInterval(timmer);
    }, [dispatch, navigate]);
    const handleTurnOutClick = () => {
        if(user.transferStatus) {
            navigate("/person/turnout");
        } else {
            Toast.show("您没有转账权限，如需转账请联系店主开通");
        }
    }
    return (
        <div className="person-center" style={{display: "flex", height: "100%", flexDirection: "column", backgroundColor: "var(--whole-page-background)"}}>
            <div style={{ flex: "1 1", overflowY: "auto" }}>
                <div className="personalInfoContainer">
                    <Space direction="horizontal" align="start">
                        <div className="avatar">
                            <Image src={user.avatar || DefaultUserPNG} width={50} height={50} fit='fill' style={{ borderRadius: "50%" }} onClick={() => (navigate("/person/myinfo"))}/>
                        </div>
                    </Space>
                    <div className="name row space-between align-items-center">
                        <div>{user.username || user.phone}</div>
                        <div className="row space-between person-icon align-items-center">
                            <div style={{paddingTop: "3px"}}>
                                <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1482" width="200" height="200"><path d="M183.72 699.01h-67.13c-16.57 0-30-13.44-30-30V448.32c0-16.57 13.43-30 30-30h67.13c60.65 0 110 49.35 110 110v60.69c0 60.65-49.34 110-110 110z m-37.13-60h37.13c27.57 0 50-22.42 50-50v-60.69c0-27.57-22.43-50-50-50h-37.13v160.69zM907.41 717.39h-67.13c-60.64 0-110-49.36-110-110v-60.68c0-60.65 49.36-110 110-110h67.13c16.56 0 30 13.43 30 30v220.68c0 16.56-13.44 30-30 30z m-67.13-220.68c-27.58 0-50 22.43-50 50v60.68c0 27.58 22.42 50 50 50h37.13V496.71h-37.13zM512 942c-41.89 0-75.98-34.08-75.98-75.98s34.08-75.98 75.98-75.98 75.98 34.08 75.98 75.98S553.89 942 512 942z m0-91.95c-8.81 0-15.98 7.17-15.98 15.98S503.19 882 512 882s15.98-7.17 15.98-15.98-7.18-15.97-15.98-15.97z" p-id="1483" fill="#ffffff"></path><path d="M907.41 509.81c-16.56 0-30-13.43-30-30C877.41 293.54 713.48 142 512 142S146.6 293.54 146.6 479.81c0 16.57-13.43 30-30 30s-30-13.43-30-30C86.59 260.46 277.43 82 512 82s425.41 178.46 425.41 397.81c0 16.57-13.44 30-30 30zM583.42 897.33c-14.43 0-25.21-0.74-30.94-1.82-16.29-3.03-27.03-18.69-24-34.98s18.65-27.11 34.98-24c21.13 3.95 182.58-0.61 266.5-70.37 31.91-26.52 47.44-58.3 47.44-97.15 0-16.56 13.44-30 30-30s30 13.44 30 30c0 56.97-23.24 105.2-69.08 143.3-84.25 70.02-220.7 85.02-284.9 85.02z" p-id="1484" fill="#ffffff"></path></svg>
                            </div>
                            <div>
                                <SetOutline />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="order-history column space-around" style={{marginTop: "-40px", height: "160px"}}>
                    <div className="row space-between">
                        <div className="personal-label">我的钱包</div>
                        <div className="personal-tips" onClick={() => { navigate("/person/action/list", { state: {id: user._id} }) }}>交易明细 <RightOutline /></div>
                    </div>
                    <div className="row space-between">
                        <div className="flex-1">
                            <div className="personal-highlight">{ user.balance?.toFixed(2) }</div>
                            <div className="person-ps">账户总额（元）</div>
                        </div>
                        <div className="flex-1">
                            <div className="personal-highlight">{ todayWon.toFixed(2) }</div>
                            <div className="person-ps">今日中奖（元）</div>
                        </div>
                    </div>
                    <div className="row space-around" style={{ gap: '5px' }}>
                        <Button color="primary" block size="small" style={{ width: "110px", height: "36px" }} onClick={() => navigate("/person/chargemoney")}>充值</Button>
                        <Button color="default" block size="small" style={{ width: "110px", height: "36px", backgroundColor: 'green', color: 'white' }} onClick={handleTurnOutClick}>转账</Button>
                        <Button color="default" block size="small" style={{ width: "110px", height: "36px", backgroundColor: '#69a0f5', color: 'white' }} onClick={() => navigate("/person/drawmoney")}>提款</Button>
                    </div>
                </div>

                <div className="order-history column space-around" style={{height: "100px"}}>
                    <div className="row space-between">
                        <div className="personal-label">投注记录</div>
                        <div className="personal-tips" onClick={() => {navigate("/person/bet/list", { state: {id: user._id, tab: "0,1,2,3,4,5,6"} })}}>全部记录 <RightOutline /></div>
                    </div>
                    <div className="row space-between">
                        <div className="column flex-1 align-items-center" onClick={() => {navigate("/person/bet/list", { state: {id: user._id, tab: "0,1"} })}}>
                            <div className="personal-highlight">{ noTicketNum }</div>
                            <div className="personalInfoToolItem">待出票</div>
                        </div>
                        <div className="column flex-1 align-items-center" onClick={() => {navigate("/person/bet/list", { state: {id: user._id, tab: "2"} })}}>
                            <div className="personal-highlight">{ waitForLotteryNum }</div>
                            <div className="personalInfoToolItem">待开奖</div>
                        </div>
                        <div className="column flex-1 align-items-center" onClick={() => {navigate("/person/bet/list", { state: {id: user._id, tab: "4,5"} })}}>
                            <div className="personal-highlight">{ wonNum }</div>
                            <div className="personalInfoToolItem">已中奖</div>
                        </div>
                    </div>
                </div>

                <div className="order-history column space-around">
                    <Grid columns={4} gap={14}>
                        <Grid.Item>
                            <AutoCenter>
                                <Space direction="vertical" align="center" onClick={() => navigate("/person/groupbuy/me")}>
                                    <img className="personal-tools-icon" src={AllInPNG} alt="AllInHistory" />
                                    <span className="personalInfoToolItem">合买记录</span>
                                </Space>
                            </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                            <AutoCenter>
                                <Space direction="vertical" align="center" onClick={() => navigate("/person/chasenum/list")}>
                                    <img className="personal-tools-icon" src={TrackHistoryPNG} alt="TrackHistory" />
                                    <span className="personalInfoToolItem">追号记录</span>
                                </Space>
                            </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                            <div>
                                <AutoCenter>
                                    <Space direction="vertical" align="center" onClick={() => navigate("/person/bet/save")}>
                                        <img className="personal-tools-icon" src={SaveHistoryPNG} alt="SaveHistory" />
                                        <span className="personalInfoToolItem">保存记录</span>
                                    </Space>
                                </AutoCenter>
                            </div>
                        </Grid.Item>
                        <Grid.Item>
                            <div>
                                <AutoCenter>
                                    <Space direction="vertical" align="center" onClick={() => navigate("/person/shopinfo")}>
                                        <img className="personal-tools-icon" src={StoreInfo} alt="StoreInfo" />
                                        <span className="personalInfoToolItem">店铺信息</span>
                                    </Space>
                                </AutoCenter>
                            </div>
                        </Grid.Item>
                        <Grid.Item>
                            <div>
                                <AutoCenter>
                                    <Space direction="vertical" align="center" onClick={() => navigate("/person/chat/group")}>
                                        <img className="personal-tools-icon" src={UserManagementPNG} alt="UserManagement" />
                                        <span className="personalInfoToolItem">店铺群聊</span>
                                    </Space>
                                </AutoCenter>
                            </div>
                        </Grid.Item>
                        <Grid.Item>
                            <AutoCenter>
                                <Space direction="vertical" align="center"
                                    onClick={() => navigate("/share/shop")}>
                                    <img className="personal-tools-icon" src={SharePNG} alt="Share" />
                                    <span className="personalInfoToolItem">分享好友</span>
                                </Space>
                            </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                            <AutoCenter>
                                <Space direction="vertical" align="center"
                                    onClick={() => navigate("/person/draw/list")}>
                                    <img className="personal-tools-icon" src={FeedbackPNG} alt="Feedback" />
                                    <span className="personalInfoToolItem">提现历史</span>
                                </Space>
                            </AutoCenter>
                        </Grid.Item>
                        <Grid.Item>
                            <AutoCenter>
                                <Space direction="vertical" align="center" onClick={() => navigate("/person/myinfo")}>
                                    <img className="personal-tools-icon" src={AboutPNG} alt="About" />
                                    <span className="personalInfoToolItem">我的资料</span>
                                </Space>
                            </AutoCenter>
                        </Grid.Item>
                    </Grid>
                </div>

                <List className="order-history" style={{ padding: "0px"}}>
                    <List.Item prefix={<BellOutline />} onClick={() => {navigate("/person/chat")}}>
                        联系店主
                    </List.Item>
                    <List.Item prefix={<QuestionCircleOutlined />} onClick={() => {navigate("/person/help/center")}}>
                        帮助中心
                    </List.Item>
                    {/* <List.Item prefix={<SetOutline />} onClick={() => {}}>
                        推送设置
                    </List.Item> */}
                    <List.Item prefix={<SetOutline />} onClick={() => {navigate("/person/agentsummarize")}}>
                        用户管理
                    </List.Item>
                    {
                        user.role === 'docking' && (
                            <List.Item prefix={<PayCircleOutline />} onClick={() => {navigate('/docking/update/list')}}>
                                每日上报
                            </List.Item>
                        )
                    }
                    <List.Item prefix={<StopOutline />} onClick={() => {handleLogout()}}>
                        登出账户
                    </List.Item>
                </List>
                <div style={{ textAlign: "center", marginBottom: '10px' }}>
                    V{version}
                </div>
            </div>
            <Footer />
        </div>
    )
}

